.card-link {
  text-decoration: none;
  color: inherit;
  .card {
    background-color: #f3f3f3;
    border-radius: 9px;
    border: 1px solid #e9e9e9;
    padding: 15px;
    .card-content {
      .icon-container {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
      }
      .card-data {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        line-height: 12px;
        .card-data-heading {
          font-size: 24px;
          font-weight: bold;
        }
        .card-subtitle {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          .card-price {
            font-weight: 700;
            font-size: 18px;
            color: #8d8c8c;
          }
          .card-selling {
            font-size: 14px;
            font-weight: 500;
            color: #8d8c8c;
          }
        }
      }
    }
}
}
