.seo-page {
  .seo-inner {
    border-width: 1px;
    border-style: solid;
    border-color: rgba(241, 243, 244, 1);
    border-radius: 6px;
    padding: 10px 0;

    .seo-info-row {
      list-style: none;
      padding: 0 10px;
      display: grid;
      grid-template-columns: 2fr 3fr 1fr;
      gap: 40px;
      font-weight: 600;
      font-size: 14px;
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(241, 243, 244, 1);

      .seo-info-item {
        width: 150px;
      }
    }

    .seo-page-list {
      list-style: none;
      padding: 0 10px;
      gap: 40px;

      .seo-page-item + .seo-page-item {
        border-top: 1px solid rgba(241, 243, 244, 1);
      }

      .seo-page-item {
        display: grid;
        grid-template-columns: 2fr 3fr 1fr;
        align-items: center;

        .seo-name {
          white-space: nowrap;
          width: 150px;
        }

        .seo-page-button {
          width: 62px;
          height: 32px;
        }
      }
    }
  }
}


