// .chatbox {
//     background: url('../../public/assets/images/darkmode_background.jpg') repeat;
//     .chatbox-body {
//       .chat-box {
//         background: rgb(26, 26, 26);
//         .message-text {
//           color: #fff;
//         }
//         &.chat-left {
//           background: rgb(69, 69, 69);
//         }
//       }
//     }
//   }
//   .chatbar {
//     background: #000;
//     .profiles {
//       background: #000;
//     }
//     .avatar {
//       background: #000;
//     }
//     .stores {
//       background: #000;
//       .chat-component {
//         border-top: 1px solid #2e2e2e;
//       }
//     }
//     .oasis {
//       background: #2e2e2e;
//       .title-div {
//         background: #000;
//         border-bottom: 1px solid #2e2e2e;
//       }
//       .oasis-chats {
//         background: #000;
//       }
//       .chat-component {
//         border-top: 1px solid #2e2e2e;
//       }
//     }
//   }

.chatbar {
  background: #ffffff;
  max-width: 340px;
  width: 100%;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  height: 100vh;
  overflow-y: auto;
  z-index: 3;
  @media screen and (max-width: 767.98px) {
    max-width: 100%;
    width: 100%;
    padding-bottom: 90px;
  }
  .profile {
    padding: 26px 16px 26px 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .avatar {
      display: flex;
      gap: 13px;
      align-items: center;
      .img-div {
        width: 48px;
        height: 48px;
        position: relative;
        img {
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .name {
        display: flex;
        flex-direction: column;
        gap: 2px;
        h4 {
          margin: 0;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: #2e2e2e;
        }
        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #2bc1ff;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      button {
        background: none;
        border: none;
        .img-div {
          position: relative;
          width: 22px;
          height: 22px;
        }
      }
    }
  }
  .stores {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
  }
  .oasis {
    // padding-top: 17px;
    background: #f2f2f2;
    .title-div {
      background: #ffffff;
      border-bottom: 1px solid #f5f5f5;
      h4 {
        margin: 0;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #2e2e2e;
        padding: 15px 26px;
      }
    }
    .oasis-chats {
      background: #fff;
    }
  }
}

.chat-component {
  cursor: pointer;
  border-top: 1px solid #f5f5f5;
  padding: 11px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.25s;
  &.selected {
    background: #f2f2f2;
  }
  .left {
    display: flex;
    align-items: center;
    gap: 18px;
    .avatar {
      display: flex;
      align-items: center;
      gap: 9px;
      .img-div {
        width: 48px;
        height: 48px;
        position: relative;
        img {
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .tick {
        width: 6px;
        height: 6px;
        background: transparent;
        border-radius: 100px;
        &.yellow {
          background: #f2c94c;
        }
        &.blue {
          background: #06b6ff;
        }
        &.red {
          background: #ec638d;
        }
      }
    }
    .name {
      display: flex;
      flex-direction: column;
      gap: 3px;
      h3 {
        margin: 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #2e2e2e;
      }
      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #777777;
      }
    }
  }
  .right {
    .img-div {
      width: 24px;
      height: 24px;
      position: relative;
    }
    .tick {
      width: 6px;
      height: 6px;
      background: transparent;
      border-radius: 50%;
      &.yellow {
        background: #f2c94c;
      }
      &.blue {
        background: #06b6ff;
      }
      &.red {
        background: #ec638d;
      }
    }
  }
}

.chatbox {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: url('../../public/assets/images/chat_background.png') repeat;
  background-size: cover;
  @media screen and (max-width: 767.98px) {
    height: calc(100vh - 91px);
    min-height: fit-content;
  }
  .chatbox-header {
    width: 100%;
    padding: 12px;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    .chatbox-about {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        align-items: center;
        gap: 15px;
        .backto {
          cursor: pointer;
          width: 34px;
          height: 28px;
          position: relative;
          background: none;
          padding: 0;
          margin: 0 15px;
          border: none;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
        .avatar {
          cursor: pointer;
          .img-div {
            width: 48px;
            height: 48px;
            position: relative;
            border-radius: 50%;

            img {
              object-fit: cover;
              border-radius: 50%;
            }
          }
        }
        .info {
          display: flex;
          flex-direction: column;
          gap: 4px;
          .main {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #2e2e2e;
          }
          .date {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #777777;
          }
        }
      }
      .right {
        display: flex;
        gap: 16px;
        align-items: center;
        button {
          background: none;
          border: none;
          .img-div {
            width: 22px;
            height: 22px;
            position: relative;
          }
        }
      }
    }
  }
}

.chatbox-body {
  width: 100%;
  padding: 20px 40px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  height: 80vh;
  min-height: 80vh;
  overflow-y: scroll;
  @media screen and (max-width: 767.98px) {
    height: calc(80vh - 71px);
    min-height: fit-content;
  }
  .chat-box {
    position: relative;
    max-width: 450px;
    
    flex: none;
    align-self: self-end;
    min-width: 200px;
    width: fit-content;
    background: #eaf9ff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 20px 20px 5px 20px;
    &:last-child {
      margin-bottom: 0.8rem;
    }
    .user-message-box {
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      align-items: flex-end;
      grid-gap: 5px;
      .message-text {
        margin: 0;
        // max-width: 440px;
        width: 100%;
        padding: 13px 26px 24px;
        position: relative;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #2e2e2e;
      }
      .date {
        position: absolute;
        right: 12px;
        bottom: 5px;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #2bc1ff;
      }
    }
    &.chat-left {
      align-self: self-start;
      background: #ffffff;
      border-radius: 20px 20px 20px 5px;
      .user-message-box {
        align-items: flex-start;
        .profile-avatar {
          flex-direction: row-reverse;
        }
      }
      .date {
        color: #777777;
      }
    }
  }
}

.chatbox-footer {
  margin-top: auto;
  padding: 0 80px;
  width: 100%;
  @media screen and (max-width: 767.98px) {
    padding: 0 20px;
  }
  .message-form {
    display: flex;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    grid-gap: 35px;
    width: 100%;
    height: 50px;
    padding: 4px 4px 4px 20px;
    background: #ffffff;
    border: 1px solid #f0f0f0;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
    .form-chat-right {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      grid-gap: 14px;
    }
  }
}

.chatbox-footer .message-form input.input-message {
  background: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #2e2e2e;
  width: 100%;
  border: none;
  outline: none;
}
.chatbox-footer .message-form input.input-message::placeholder {
  color: #2e2e2e;
  opacity: 0.5;
}

.chatbox-footer .message-form button[type='submit'] {
  cursor: pointer;
  padding: 9px 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  /* max-width: 102px; */
  background: #06b6ff;
  box-shadow: 0px 2px 5px rgba(26, 49, 150, 0.25);
  border-radius: 10px;
  transition: 0.25s;
  &:hover {
    background: #2bc1ff;
  }
}

.chatbox-footer .message-form button[type='submit'] span {
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
}
